var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root fill-height"
  }, [_c('v-row', {
    staticClass: "login login-signin-on background fill-height",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "login-aside primary d-flex flex-column flex-row-auto",
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column-auto flex-column pt-lg-30 pt-10"
  }, [_c('router-link', {
    staticClass: "text-center mb-20",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "max-h-60px",
    attrs: {
      "src": "/media/logos/logo_light_155x30.png",
      "alt": "TestApp.io"
    }
  })]), _c('h3', {
    staticClass: "bold-text text-center white--text font-size-h4 text-white px-7 mt-4 pb-3",
    staticStyle: {
      "line-height": "1.5"
    }
  }, [_vm._v(" App distribution made easy ")])], 1), _c('div', {
    staticClass: "d-flex flex-column align-center justify-center fill-height mb-5 text-center sign-in-banner"
  }, [_c('div', {
    staticClass: "d-flex justify-center px-3 py-4 fill-width align-center my-0 my-md-1 my-lg-2 my-xl-7"
  }, [_c('span', {
    staticClass: "img"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "height": "120",
      "src": "/media/svg/signin/upload.svg"
    }
  })], 1), _c('h3', {
    staticClass: "white--text font-weight-regular title"
  }, [_vm._v(" Upload your app (Android & iOS) manually or via "), _c('a', {
    staticClass: "white--text text-decoration-underline",
    attrs: {
      "href": "https://github.com/testappio/cli",
      "target": "_blank"
    }
  }, [_vm._v("CLI")]), _vm._v(" or using "), _c('a', {
    staticClass: "white--text text-decoration-underline",
    attrs: {
      "href": " https://github.com/marketplace/actions/testapp-io-app-distribution",
      "target": "_blank"
    }
  }, [_vm._v("Github Action")])])]), _c('div', {
    staticClass: "d-flex justify-center px-3 py-4 fill-width align-center my-0 my-md-1 my-lg-2 my-xl-7"
  }, [_c('h3', {
    staticClass: "white--text font-weight-regular title"
  }, [_vm._v(" Share it with testers, clients, friends family, colleagues... anyone! ")]), _c('span', {
    staticClass: "img"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "height": "150",
      "src": "/media/svg/signin/share.svg"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex justify-center px-3 py-4 fill-width align-center my-0 my-md-1 my-lg-2 my-xl-7"
  }, [_c('span', {
    staticClass: "img"
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "height": "150",
      "src": "/media/svg/signin/feedback.svg"
    }
  })], 1), _c('h3', {
    staticClass: "white--text font-weight-regular title"
  }, [_vm._v(" Collect feedback from your testers from "), _c('a', {
    staticClass: "white--text text-decoration-underline",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'start'
        });
      }
    }
  }, [_vm._v(" TestApp.io app")]), _vm._v(" and public install page ")])])])]), _c('v-col', {
    staticClass: "login-content flex-row-fluid d-flex flex-column justify-center position-relative overflow-hidden pa-7 mx-auto",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "cols": "12",
      "md": "7",
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-center align-center flex-grow-1"
  }, [_c('router-view')], 1), _c('v-row', {
    staticClass: "pt-7 py-lg-0 text-center pt-sm-13 pb-sm-0",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "px-0 pb-4 align-end",
    attrs: {
      "cols": "12",
      "md": "3",
      "align-self": "end"
    }
  }, [_c('a', {
    staticClass: "text-primary ml-4 mr-4 bold-text font-size-h4 min-w-90px text-center",
    attrs: {
      "href": "https://testapp.io"
    }
  }, [_vm._v(" Home ")])]), _c('v-col', {
    staticClass: "px-0 pb-4 align-end",
    attrs: {
      "cols": "12",
      "md": "3",
      "align-self": "end"
    }
  }, [_c('a', {
    staticClass: "text-primary ml-4 mr-4 bold-text font-size-h4 min-w-90px text-center",
    attrs: {
      "href": "https://testapp.io/terms-and-conditions"
    }
  }, [_vm._v(" Terms ")])]), _c('v-col', {
    staticClass: "px-0 pb-4 align-end",
    attrs: {
      "cols": "12",
      "md": "3",
      "align-self": "end"
    }
  }, [_c('a', {
    staticClass: "text-primary ml-4 mr-4 bold-text font-size-h4 min-w-90px text-center",
    attrs: {
      "href": "https://testapp.io/privacy-policy"
    }
  }, [_vm._v(" Privacy Policy ")])]), _c('v-col', {
    staticClass: "px-0 pb-4 align-end",
    attrs: {
      "cols": "12",
      "md": "3",
      "align-self": "end"
    }
  }, [_c('a', {
    staticClass: "text-primary ml-4 mr-4 bold-text font-size-h4 min-w-90px text-center",
    attrs: {
      "href": "https://testapp.io/contact"
    }
  }, [_vm._v(" Contact Us ")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }