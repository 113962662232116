<template>
  <div class="d-flex flex-column flex-root fill-height">
    <v-row class="login login-signin-on background fill-height" no-gutters>
      <v-col
        cols="12"
        md="5"
        lg="4"
        class="login-aside primary d-flex flex-column flex-row-auto"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-30 pt-10">
          <router-link to="/" class="text-center mb-20">
            <img
              src="/media/logos/logo_light_155x30.png"
              class="max-h-60px"
              alt="TestApp.io"
            />
          </router-link>
          <h3
            class="
              bold-text
              text-center
              white--text
              font-size-h4
              text-white
              px-7
              mt-4
              pb-3
            "
            style="line-height: 1.5"
          >
            App distribution made easy
          </h3>
        </div>
        <div
          class="
            d-flex
            flex-column
            align-center
            justify-center
            fill-height
            mb-5
            text-center
            sign-in-banner
          "
        >
          <div
            class="
              d-flex
              justify-center
              px-3
              py-4
              fill-width
              align-center
              my-0 my-md-1 my-lg-2 my-xl-7
            "
          >
            <span class="img">
              <v-img contain height="120" src="/media/svg/signin/upload.svg" />
            </span>
            <h3 class="white--text font-weight-regular title">
              Upload your app (Android & iOS) manually or via
              <a
                href="https://github.com/testappio/cli"
                target="_blank"
                class="white--text text-decoration-underline"
                >CLI</a
              >
              or using
              <a
                href=" https://github.com/marketplace/actions/testapp-io-app-distribution"
                target="_blank"
                class="white--text text-decoration-underline"
                >Github Action</a
              >
            </h3>
          </div>
          <div
            class="
              d-flex
              justify-center
              px-3
              py-4
              fill-width
              align-center
              my-0 my-md-1 my-lg-2 my-xl-7
            "
          >
            <h3 class="white--text font-weight-regular title">
              Share it with testers, clients, friends family, colleagues...
              anyone!
            </h3>
            <span class="img">
              <v-img contain height="150" src="/media/svg/signin/share.svg" />
            </span>
          </div>
          <div
            class="
              d-flex
              justify-center
              px-3
              py-4
              fill-width
              align-center
              my-0 my-md-1 my-lg-2 my-xl-7
            "
          >
            <span class="img">
              <v-img
                contain
                height="150"
                src="/media/svg/signin/feedback.svg"
              />
            </span>
            <h3 class="white--text font-weight-regular title">
              Collect feedback from your testers from
              <a
                @click="$router.push({ name: 'start' })"
                target="_blank"
                class="white--text text-decoration-underline"
              >
                TestApp.io app</a
              >
              and public install page
            </h3>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="8"
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-center
          position-relative
          overflow-hidden
          pa-7
          mx-auto
        "
        style="width: 100%"
      >
        <div class="d-flex flex-column justify-center align-center flex-grow-1">
          <router-view></router-view>
        </div>
        <v-row class="pt-7 py-lg-0 text-center pt-sm-13 pb-sm-0" no-gutters>
          <v-col cols="12" md="3" class="px-0 pb-4 align-end" align-self="end">
            <a
              href="https://testapp.io"
              class="
                text-primary
                ml-4
                mr-4
                bold-text
                font-size-h4
                min-w-90px
                text-center
              "
            >
              Home
            </a>
          </v-col>
          <v-col cols="12" md="3" class="px-0 pb-4 align-end" align-self="end">
            <a
              href="https://testapp.io/terms-and-conditions"
              class="
                text-primary
                ml-4
                mr-4
                bold-text
                font-size-h4
                min-w-90px
                text-center
              "
            >
              Terms
            </a>
          </v-col>
          <v-col cols="12" md="3" class="px-0 pb-4 align-end" align-self="end">
            <a
              href="https://testapp.io/privacy-policy"
              class="
                text-primary
                ml-4
                mr-4
                bold-text
                font-size-h4
                min-w-90px
                text-center
              "
            >
              Privacy Policy
            </a>
          </v-col>
          <v-col cols="12" md="3" class="px-0 pb-4 align-end" align-self="end">
            <a
              href="https://testapp.io/contact"
              class="
                text-primary
                ml-4
                mr-4
                bold-text
                font-size-h4
                min-w-90px
                text-center
              "
            >
              Contact Us
            </a>
          </v-col>
        </v-row>

        <!-- </div> -->
      </v-col>
    </v-row>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
// @import "@/assets/sass/pages/login/login.scss";
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  methods: {},
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-4.jpg";
    },
  },
  created() {
    this.$vuetify.theme.dark = false;
  },
};
</script>
